import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRoutingModule } from './views.routing';
import { SharedModule } from '../shared/shared.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { ComponentsCarouselModule } from 'src/app/components/carousel/components.carousel.module';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { HomeComponent } from './home/home.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AuthGuard } from '../shared/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CreateCompanyComponent} from './createCompany/createCompany.component';
import {ArchwizardModule} from 'angular-archwizard';
import {LayoutContainersModule} from '../containers/layout/layout.containers.module';
@NgModule({
  declarations: [HomeComponent , CreateCompanyComponent],
  imports: [
    CommonModule,
    ViewRoutingModule,
    SharedModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    HeadroomModule,
    ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    LayoutContainersModule
  ],
  providers: [AuthGuard],
})
export class ViewsModule {}
