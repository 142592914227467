  <footer class="page-footer">
    <div class="footer-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p class="mb-0 text-muted">DLTCode 2021</p>
          </div>
          <div class="col-sm-6 d-none d-sm-block">
            <ul class="breadcrumb pt-0 pr-0 float-right">
              <li class="breadcrumb-item mb-0">
                <a href="https://www.linkedin.com/company/dlt-code" class="btn-link">LikedIn</a>
              </li>
              <li class="breadcrumb-item mb-0">
                <a href="tel:+34933173774" class="btn-link">+34 933173774</a>
              </li>
              <li class="breadcrumb-item mb-0">
                <a href="mailto:info@dltcode.es" class="btn-link">info@dltcode.es</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
