import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private userState = new BehaviorSubject<boolean>(this.token.isLoggedIn());
  userAuthState = this.userState.asObservable();
  constructor(public token: TokenService) { }
// tslint:disable-next-line:typedef
  setAuthState(value: boolean) {
    this.userState.next(value);
  }
}
